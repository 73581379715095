import React, { useState, useEffect, useCallback, memo } from 'react';
import { Button as ButtonTuring } from '../../../../../ds-components';
import { requester } from '../../../../../requesters';
import InteiroTeorFilter from './Filters/InteiroTeorFilter';
import FilterWrapper from './FilterWrapper';
import { FiltersSkeleton } from '../../../../../components/Skeleton';
import NewDateFilter from './Filters/NewFilterList/components/NewDateFilter/NewDateFilter';
import TaxFilters from './Filters/NewTaxFilters/TaxFilters';
import { NewFilterList } from './Filters/NewFilterList/NewFilterList';
import { getResultById } from './Filters/NewFilterList/FilterPath';
import './filterList.scss';

// FIXME: Otimização para o componente não rendeizar se as props não mudarem
const FiltersList = memo((props) => {
  const [trigger, setTrigger] = useState(false);
  const [filters, setFilters] = useState(null);
  const [requesting, setRequesting] = useState(true);
  const [selectedDecisionOptions, setSelectedDecisionOptions] = useState([]);
  const [selectedTypeOptions, setSelectedTypeOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [appliedFilterId, setAppliedFilterId] = useState(null);
  const [clear, setClear] = useState(false);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [refreshRelator, setRefreshRelator] = useState(false);
  const [resultById, setResultById] = useState(null);

  useEffect(() => {
    setRefreshRelator(!refreshRelator);
  }, [props.isSearching]);

  useEffect(() => {
    let storedFields = localStorage.getItem('fields');
    if (!storedFields) {
      storedFields = JSON.stringify(['ementa', 'full_text']);
      localStorage.setItem('fields', storedFields);
    }
    getFilters();
    getResultByIdAndHandle();
  }, []);

  useEffect(() => {
    if (appliedFilterId !== null) {
      getResultByIdAndHandle();
    }
  }, [appliedFilterId]);

  const getFilters = () => {
    if (!props.filters) {
      setRequesting(true);
      requester.cards
        .getAllFilters()
        .then((response) => {
          const filtersResp = response;
          let defaultFilters = {};

          setFilters(filtersResp);

          filtersResp.forEach((filter) => {
            if (filter.field_name === 'entity') {
              defaultFilters.entities = filter.default;
            } else {
              defaultFilters[filter.field_name] = filter.default;
            }
          });
          props.filtersProps?.setOriginalFilters(filtersResp);
          props.filtersProps?.setDefaultFilters(defaultFilters);
          props.filtersProps?.loadFilters();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setRequesting(false);
        });
    }
  };

  const getResultByIdAndHandle = async () => {
    if (!appliedFilterId) return;

    try {
      const result = await getResultById(appliedFilterId);
      setResultById(result);
    } catch (error) {
      console.error('Erro ao obter o resultado por ID:', error);
    }
  };

  const clearSelectedFilters = () => {
    setSelectedDecisionOptions([]);
    setSelectedTypeOptions([]);
    setSelectedFilters({});
    setAppliedFilterId(null);
  };

  // FIXME: Evitar renderizações desnecessárias
  // O useCallback é para performance(dos componentes abaixo não renderizarem)
  const updateSelectedFilters = useCallback((updatedValues) => {
    setSelectedFilters((prevFilters) => {
      const hasChanged = Object.keys(updatedValues).some(
        (key) => prevFilters[key] !== updatedValues[key],
      );

      if (!hasChanged) return prevFilters;

      return { ...prevFilters, ...updatedValues };
    });
  }, []);

  // const updateSelectedFilters = (updatedValues) => {
  //   setSelectedFilters((prevFilters) => ({
  //     ...prevFilters,
  //     ...updatedValues,
  //   }));
  // };

  const handleDecisionOptionChange = (id) => {
    setSelectedDecisionOptions((prevOptions) =>
      prevOptions.includes(id)
        ? prevOptions.filter((option) => option !== id)
        : [...prevOptions, id],
    );
  };

  const handleTypeOptionChange = (id) => {
    setSelectedTypeOptions((prevOptions) =>
      prevOptions.includes(id)
        ? prevOptions.filter((option) => option !== id)
        : [...prevOptions, id],
    );
  };

  const handleStateUpdate = (newState) => {
    if (newState && newState.dateRange) setDateRange(newState.dateRange);
    if (newState && newState.trigger !== undefined)
      setTrigger(newState.trigger);
    if (newState && newState.clear !== undefined) setClear(newState.clear);
  };

  const isQueryEmpty = !props.query || props.query.trim() === '';

  return (
    <div
      className="tv-filters_list w-[400px] md:w-[90vw] mt-[194px]"
      style={{
        transition: 'width 0.6s ease',

        marginTop:
          window.location.pathname == '/pesquisa'
            ? window.innerHeight < 920
              ? '70px'
              : '100px'
            : props.selectedCards?.length > 0
              ? '185px'
              : '132px',
        transition: 'margin-top 0.2s ease-in-out',
      }}
    >
      {requesting ? (
        <FiltersSkeleton />
      ) : (
        <>
          <div className="tv-filters_body">
            <NewFilterList
              selectedFilters={selectedFilters}
              updateSelectedFilters={updateSelectedFilters}
              filtersProps={props.filtersProps}
              setValueStyles={props.setValueStyles}
              search={props.search}
              setNewValue={props.setNewValue}
              setValitadionFilter={props.setValitadionFilter}
              handleStateUpdate={handleStateUpdate}
              setClearFiltro={props.setClearFiltro}
              trigger={trigger}
              setTrigger={() => setTrigger(!trigger)}
              setRefreshRelator={() => setRefreshRelator(!refreshRelator)}
              colapse={props.colapse}
              setColapse={props.setColapse}
              setClear={setClear}
              clear={clear}
              query={props.query}
            />
            <InteiroTeorFilter
              context="Results"
              filtersProps={props.filtersProps}
              updateSelectedFilters={updateSelectedFilters}
              resultById={resultById?.where_search}
              setClear={setClear}
              clear={clear}
              AllProps={props?.AllProps}
            />
            <NewDateFilter
              filters={filters}
              filtersProps={props.filtersProps}
              tmpFilters={props.tmpFilters}
              entities={props.entities}
              additionalProp1={props.additionalProp1}
              additionalProp2={props.additionalProp2}
              selectedFilters={selectedFilters}
              updateSelectedFilters={updateSelectedFilters}
              endDate={resultById?.end_date}
              startDate={resultById?.start_date}
              dateRange={dateRange}
              setDateRange={setDateRange}
              trigger={trigger}
              setClear={setClear}
              clear={clear}
            />
            <form className="tv-filters_content">
              {filters
                ? filters.map((filter, index) => (
                    <FilterWrapper
                      key={index}
                      filters={filters}
                      context="Results"
                      filter={filter}
                      tmpFilters={props.tmpFilters}
                      entities={props.entities}
                      filtersProps={props.filtersProps}
                      selectedFilters={selectedFilters}
                      updateSelectedFilters={updateSelectedFilters}
                      resultById={resultById}
                      setClear={setClear}
                      clear={clear}
                      isHome={props.isHome}
                      refreshRelator={refreshRelator}
                      requesting={props.requesting}
                      isSearching={props.isSearching}
                    />
                  ))
                : null}
            </form>
          </div>
          <TaxFilters
            filters={filters}
            AllProps={props?.AllProps}
            onDecisionOptionChange={handleDecisionOptionChange}
            onTypeOptionChange={handleTypeOptionChange}
            selectedFilters={selectedFilters}
            updateSelectedFilters={updateSelectedFilters}
            resultById={resultById}
            clear={clear}
          />
        </>
      )}
      {
        <div className="tv-filters_footer">
          <ButtonTuring
            size="md"
            fullWidth
            onClick={() => {
              props.filtersProps.saveFilters(
                props.filtersProps.history,
                props.filtersProps.match,
                encodeURIComponent(props.query),
              );
              setRefreshRelator(!refreshRelator);
            }}
            disabled={isQueryEmpty}
            className="ButtonTuring"
            icon={<img src="/icon-filter.png" className="icon-filter" />}
            iconPosition="left"
          >
            Aplicar filtro{' '}
            {!!props?.AllProps?.countResults
              ? `(${props.AllProps.countResults} resultados)`
              : ''}
          </ButtonTuring>
        </div>
      }
    </div>
  );
});

export default FiltersList;
