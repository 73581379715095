import { useCallback, useMemo, useState } from 'react';
import { Turivius } from '../../../../../../ui';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import { posthog } from '../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../services/hubspot';
import DOMPurify from 'dompurify';
import './result-card-inteiro-teor.scss';

export const ResultCardInteiroTeor = ({ content, card }) => {
  const [page, setPage] = useState(1);
  const { next, previous, disabledPrevious, disabledNext } = useMemo(
    () => ({
      next: () =>
        page <= content.length ? setPage((state) => state + 1) : null,
      previous: () => (page > 1 ? setPage((state) => state - 1) : null),
      disabledPrevious: page === 1,
      disabledNext: page >= content.length,
    }),
    [page, content],
  );

  const { url, id, entity } = card;

  const handleUrl = () => {
    window.open(url, '_blank');
    posthog.capture('open_integra', {
      id,
      entity,
      action: 'open_integra',
    });
    postHubSpotEvent('app_openintegra', {
      app_openintegra: id,
    });
  };

  // Função para sanitizar o conteúdo HTML
  const sanitizeContent = (content) => {
    return DOMPurify.sanitize(content);
  };

  const cleanText = useCallback((text) => {
    const x = text
      .replace(/<(?!\/?mark\b)[^<>]+>/gi, '') // Remove todas as tags HTML, exceto <mark> e </mark>

      .replace(/\$\(.+?\);/g, '') // Remove trechos JavaScript do PrimeFaces
      .replace(/\s+/g, ' ') // Remove múltiplos espaços
      .trim()
      .replace(/_+/g, ' ')
      .replace(/_{2,}/g, ' ')
      .replace(/["'&{}();]/g, ' '); // Remove caracteres problemáticos sem afetar <mark>

    return sanitizeContent(x);
  });

  return (
    <div className="wrapper">
      <div className="header">
        {/* <span className="badge">Novidade</span> */}
        <Turivius.TuriviusTooltip
          trigger="hover"
          placement="top"
          message="Ver íntegra"
        >
          <span className="title" onClick={handleUrl}>
            Íntegra:
          </span>
        </Turivius.TuriviusTooltip>
        <div className="pagination">
          <span className="text">
            Página {page} de {content.length}
          </span>
          <div
            onClick={(event) => {
              // evitar que o bulk action seja ativo
              event.stopPropagation();
            }}
          >
            {disabledPrevious ? (
              <button
                data-testid="previous-page-inteiro-teor"
                disabled={disabledPrevious}
                onClick={previous}
              >
                <ArrowLeft2 size="16" color="#667085" />
              </button>
            ) : (
              <Turivius.TuriviusTooltip
                trigger="hover"
                placement="autoVerticalStart"
                message="Anterior"
              >
                <button
                  data-testid="previous-page-inteiro-teor"
                  disabled={disabledPrevious}
                  onClick={previous}
                >
                  <ArrowLeft2 size="16" color="#667085" />
                </button>
              </Turivius.TuriviusTooltip>
            )}
            {disabledNext ? (
              <button
                data-testid="next-page-inteiro-teor"
                disabled={disabledNext}
                onClick={next}
              >
                <ArrowRight2 size="16" color="#667085" />
              </button>
            ) : (
              <Turivius.TuriviusTooltip
                trigger="hover"
                placement="autoVerticalStart"
                message="Próximo"
              >
                <button
                  data-testid="next-page-inteiro-teor"
                  disabled={disabledNext}
                  onClick={next}
                >
                  <ArrowRight2 size="16" color="#667085" />
                </button>
              </Turivius.TuriviusTooltip>
            )}
          </div>
        </div>
      </div>
      <p
        className="p-inf-text-result-card"
        dangerouslySetInnerHTML={{ __html: cleanText(content[page - 1]) }}
      />
    </div>
  );
};
