import React from 'react';
import { Grid, Row, Col, Checkbox } from 'rsuite';
import { Turivius } from '../../../../../../../ui';
import { posthog } from '../../../../../../../requesters';

import '../TuriviusCheckboxGroupFilter/TuriviusCheckboxGroupFilter.scss';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';
class TuriviusCheckboxFilter extends React.Component {
  state = {
    requesting: false,
    items: null,
    filters: null,
    selected: null,
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.getOptions('');
    if (this.mounted) {
      this.setState({
        filters: this.props.filtersProps.filters[this.props.filterReference],
        selected: this.props.filtersProps.filters[this.props.filterReference]
          ? this.props.filtersProps.filters[this.props.filterReference]
          : [],
        placeholder: this.props.placeholder,
      });
    }
  }

  componentDidUpdate() {
    const propsFilters =
      this.props.filtersProps.filters &&
      this.props.filtersProps.filters[this.props.filterReference];
    const stateFilters = this.state && this.state.filters;
    if (!stateFilters && propsFilters && this.mounted) {
      this.setState({
        filters: [...propsFilters],
        selected: [...propsFilters],
      });
    } else if (
      stateFilters &&
      propsFilters &&
      stateFilters.length !== propsFilters.length &&
      this.mounted
    ) {
      this.setState({
        filters: null,
        selected: null,
      });
      setTimeout(() => {
        this.setState({
          filters: [...propsFilters],
          selected: [...propsFilters],
        });
      }, 100);
    } else if (
      Array.isArray(propsFilters) &&
      Array.isArray(stateFilters) &&
      this.mounted
    ) {
      let doUpdate = false;
      propsFilters.forEach((propFilter) => {
        const found = stateFilters.filter((stateFilter) => {
          return (
            (typeof stateFilter === 'object'
              ? parseInt(stateFilter.id)
              : parseInt(stateFilter)) ===
            (typeof propFilter === 'object'
              ? parseInt(propFilter.id)
              : parseInt(propFilter))
          );
        }).length;
        if (found === 0) {
          doUpdate = true;
        }
      });
      if (doUpdate && this.mounted) {
        this.setState({
          filters: null,
          selected: null,
        });
        setTimeout(() => {
          this.setState({
            filters: [...propsFilters],
            selected: [...propsFilters],
          });
        }, 100);
      }
    } else if (
      (propsFilters === undefined || propsFilters === null) &&
      (stateFilters === undefined ||
        stateFilters === null ||
        (Array.isArray(stateFilters) && stateFilters.length !== 0))
    ) {
      this.setState({
        filters: [],
        selected: [],
      });
    }
  }

  getOptions = (str) => {
    if (this.mounted) {
      this.setState({ items: this.props.filter?.options });
    }
  };

  selectionChange = (value) => {
    const { filterReference } = this.props;
    let { selected } = this.state;
    let selection = value;
    let findSelection = selected.indexOf(parseInt(selection));

    if (findSelection === -1) {
      selected = [...selected, parseInt(selection)];
    } else {
      selected.splice(findSelection, 1);
    }
    this.setState({ selected: [...selected] });
    this.props.filtersProps.setTmpFilters(filterReference, [...selected]);
    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: 'change_filter',
        id: filterReference,
      });
      postHubSpotEvent('app_changefilters', {
        app_changefilters: this.props.context,
      });
    }
  };

  globalSelection = (action) => {
    let { selected, items } = this.state;
    const { filterReference } = this.props;
    if (action === 'remove') {
      selected = [];
    } else {
      selected = items?.map((item) => item.id);
    }
    this.setState({ selected: [...selected] });
    this.props.filtersProps.setTmpFilters(filterReference, [...selected]);
    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: 'change_filter',
        id: filterReference,
      });
      postHubSpotEvent('app_changefilters', {
        app_changefilters: this.props.context,
      });
    }
  };

  // aqui pode ser uma pista para deixar tudo pronto
  render() {
    const { items, requesting, selected } = this.state;
    const {
      labelKey,
      label,
      helperText,
      error,
      onClick,
      cols,
      filterReference,
    } = this.props;

    return (
      <div
        className="turivius-checkbox-group-filter MuiFormControl-root MuiTextField-root"
        onClick={onClick ? onClick : () => {}}
      >
        {label ? <label>{label}</label> : null}
        <div className={`checkbox-input-field ${error ? 'error' : ''}`}>
          {requesting ? (
            <Turivius.SimplePreloader />
          ) : items && selected ? (
            <Grid fluid>
              <Row>
                <Col xs={24} sm={24}>
                  <div className="checkbox-group">
                    <label className="checkbox-group-category-label">
                      Global
                    </label>
                    <p
                      className="checkbox-group-category-action"
                      onClick={() => this.globalSelection('add')}
                    >
                      selecionar todos
                    </p>
                    <p
                      className="checkbox-group-category-action"
                      onClick={() => this.globalSelection('remove')}
                    >
                      remover todos
                    </p>
                  </div>
                </Col>
                {items?.map((elem, j) => {
                  return (
                    <Col
                      xs={cols ? 24 / cols : 12}
                      sm={cols ? 24 / cols : 12}
                      md={cols ? 24 / cols : 12}
                      key={j}
                    >
                      <Checkbox
                        id={`Checkbox_${filterReference}_${elem.id}`}
                        key={`Checkbox_${filterReference}_${elem.id}`}
                        value={`${elem.id}`}
                        onChange={this.selectionChange}
                        checked={
                          selected
                            ? selected.filter((option) =>
                                typeof option === 'object'
                                  ? option.id === elem.id
                                  : option === elem.id,
                              ).length > 0
                            : false
                        }
                      >
                        <div className="checkbox-group-label-container">
                          <div className="first-line">
                            {elem.is_new ? (
                              <Turivius.TuriviusTooltip
                                trigger={'hover'}
                                message={'Base de dados nova!'}
                                placement={'right'}
                              >
                                <div>
                                  <span className="new-badge">new</span>
                                </div>
                              </Turivius.TuriviusTooltip>
                            ) : elem.is_partial ? (
                              <Turivius.TuriviusTooltip
                                trigger={'hover'}
                                message={'Base de dados parcial'}
                                placement={'right'}
                              >
                                <div>
                                  <FontAwesomeIcon
                                    icon={faStarHalf}
                                    className="tiny partial"
                                  />
                                </div>
                              </Turivius.TuriviusTooltip>
                            ) : null}
                            <div>
                              {elem[labelKey]
                                .replace('(novo)', '')
                                .replace('(parcial)', '')}
                            </div>
                          </div>
                        </div>
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Grid>
          ) : null}
        </div>
        {helperText ? (
          <p
            className={`MuiFormHelperText-root MuiFormHelperText-contained ${
              error ? 'error' : ''
            }`}
          >
            {helperText}
          </p>
        ) : null}
      </div>
    );
  }
}

export default TuriviusCheckboxFilter;
