import { useState, useEffect, useRef } from 'react';
import { requester, posthog } from '../../../../requesters';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Creators as SearchActions } from '../../../../store/ducks/searchTributario';
import { Creators as ProfilesActions } from '../../../../store/ducks/profiles';
import BrowserDetection from 'react-browser-detection';

import FlagSystem from '../../../../components/FlagSystem';
import SearchBar from '../../components/search/SearchBar';
import './HomePage.scss';

import { toaster, Modal, FlexboxGrid } from 'rsuite';
import { Button, SpinningLoader } from '../../../../ds-components';
import IndependentBottomSheetNotification from '../../../account/notifications/components/BottomSheetNotification/IndependentBottomSheetNotification';
import { BigLaunchMode } from '../../components/results/Cards/Result/big-launch-mode';
import RecentSearchBox from '../../components/search/CardsHome/RecentSearchBox';
import RecentArticleBox from '../../components/search/CardsHome/RecentSearchBox';
import RecentArticlesBox from '../../components/search/CardsHome/RecentArticlesBox';
import CTA from '../../components/search/CardsHome/CTA';
import RecentSearchCard from '../../components/search/CardsHome/BottomBox';
import {
  ArchiveBook,
  AlignBottom,
  FolderOpen,
  Briefcase,
  Clock,
} from 'iconsax-react';

const HomePage = (props) => {
  const {
    saveFilters,
    tmpFilters,
    inteiroTeor,
    originalFilters,
    justSaveFilters,
    filters,
    setTmpFilters,
    entities_counter,
    clearFilters,
  } = props;

  const [entitiesMonitoring, setEntitiesMonitoring] = useState([]);

  const getEntitiesToMonitoring = () => {
    requester.cards
      .getEntities()
      .then((res) => {
        const entitiesObject = res.reduce((acc, entity, index) => {
          acc[index] = entity;
          return acc;
        }, {});
        setEntitiesMonitoring(entitiesObject);
      })
      .catch((err) => {
        setEntitiesMonitoring({});
      });
  };

  const triggerSuccessChecklist = useRef();
  const openSuccessChecklist = () => triggerSuccessChecklist.current?.open();

  const permissions = JSON.parse(localStorage.getItem('permissions'))[
    'tributario'
  ]
    ? JSON.parse(localStorage.getItem('permissions'))['tributario']
    : null;
  const [entitiesPermissions, setEntitiesPermissions] = useState({});
  const [requestingSearchHistory, setRequestingSearchHistory] = useState(false);
  const [searchHistory, setSearchHistory] = useState(null);
  const [filter, setFilter] = useState(null);
  const [requesting, setRequesting] = useState(false);
  const [relatorNames, setRelatorNames] = useState([]);
  const [entitiesOpen, setEntitiesOpen] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [seeTerms, setSeeTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.search();
  }, []);

  const browserHandler = {
    ie: () => (
      <IndependentBottomSheetNotification
        style={{ backgroundColor: '#ffcc00', color: '#343434' }}
      >
        Detectamos que você está utilizando o Internet Explorer como navegador!
        <br />
        Recomendamos que utilize o Google Chrome ou Mozilla Firefox para uma
        melhor experiência com a Turivius
      </IndependentBottomSheetNotification>
    ),
    edge: () => (
      <IndependentBottomSheetNotification
        style={{ backgroundColor: '#ffcc00', color: '#343434' }}
      >
        Detectamos que você está utilizando o Microsoft Edge como navegador!
        <br />
        Recomendamos que utilize o Google Chrome ou Mozilla Firefox para uma
        melhor experiência com a Turivius
      </IndependentBottomSheetNotification>
    ),
    safari: () => (
      <IndependentBottomSheetNotification
        style={{ backgroundColor: '#ffcc00', color: '#343434' }}
      >
        Detectamos que você está utilizando o Safari como navegador!
        <br />
        Recomendamos que utilize o Google Chrome ou Mozilla Firefox para uma
        melhor experiência com a Turivius
      </IndependentBottomSheetNotification>
    ),
    opera: () => (
      <IndependentBottomSheetNotification
        style={{ backgroundColor: '#ffcc00', color: '#343434' }}
      >
        Detectamos que você está utilizando o Opera como navegador!
        <br />
        Recomendamos que utilize o Google Chrome ou Mozilla Firefox para uma
        melhor experiência com a Turivius
      </IndependentBottomSheetNotification>
    ),
  };

  const entities = () => {
    const uhum = {};
    let permissions_trib = JSON.parse(localStorage.getItem('permissions'))[
      'tributario'
    ].entidades
      ? JSON.parse(localStorage.getItem('permissions'))['tributario'].entidades
      : null;
    let permissions_trab = JSON.parse(localStorage.getItem('permissions'))[
      'trabalhista'
    ].entidades
      ? JSON.parse(localStorage.getItem('permissions'))['trabalhista'].entidades
      : null;
    if (permissions_trib) {
      Object.entries(permissions_trib).forEach(([key, value]) => {
        uhum[key] = value;
      });
    }
    if (permissions_trab) {
      Object.entries(permissions_trab).forEach(([key, value]) => {
        uhum[key] = value;
      });
    }
    setEntitiesPermissions(uhum);
    return uhum;
  };
  const getSearchHistory = () => {
    setRequestingSearchHistory(true);
    requester.cards
      .searchHistory()
      .then((response) => {
        let searchHistoryRes = response
          .filter((r) => r.query !== '')
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) => t.query === value.query && t.name === value.name,
              ),
          )
          ?.map((r) => {
            Object.keys(r.filters).forEach((filterKey) => {
              if (filterKey === 'entity') {
                r.filters[filterKey] = r.filters[filterKey]?.map((e) =>
                  parseInt(e),
                );
              }
            });
            return r;
          });
        setSearchHistory(searchHistoryRes);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setRequestingSearchHistory(false);
      });
  };
  const getFilters = async () => {
    if (
      props.originalFilters &&
      Object.keys(props.originalFilters).length === 0
    ) {
      await requester.cards
        .getAllFilters()
        .then((response) => {
          const filters = response;
          let defaultFilters = {};
          let entityFilter = {};
          filters.forEach((filter) => {
            if (filter.field_name === 'entity') {
              defaultFilters.entities = filter.default;
              entityFilter = { ...filter };
            } else {
              defaultFilters[filter.field_name] = filter.default;
            }
          });

          props.setOriginalFilters(filters);
          props.setDefaultFilters(defaultFilters);
          props.loadFilters();
          props.restoreOptionalFilters();
          setFilter(entityFilter);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setRequesting(false);
        });
    }
  };
  const getRelatorsNames = (partial_name) => {
    if (!requesting) {
      setRequesting(true);
      requester.cards
        .getRelatorsByName(partial_name)
        .then((response) => {
          setTimeout(() => {
            setRelatorNames([...response]);
          }, 100);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setRequesting(false));
    }
  };
  const agree = () => {
    setLoading(true);
    const user = localStorage.getItem('user');
    requester.profiles
      .createUserAgreement(user, true, true)
      .then((response) => {
        return requester.profiles.getById(user);
      })
      .then((profile) => {
        localStorage.setItem('profile', JSON.stringify(profile));
        props.setProfile(profile);
      })
      .finally(() => {
        setLoading(false);
        setSeeTerms(false);
        toaster.clear();
      });
  };
  const validTerms = () => {
    let profile = JSON.parse(localStorage.getItem('profile'));
    if (profile?.agreements?.length === 0) {
      setSeeTerms(true);
    }
  };

  const products_cards = [
    {
      icon: <FolderOpen size="38" color="#fff" />,
      iconBgColor: '#005B99',
      title: 'Banco de Teses',
      description:
        'Organize, classifique e interaja com decisões jurídicas de forma eficiente.',
      buttonText: 'Ir para Coleções',
      route: '/colecoes',
    },
    {
      icon: <Clock size="38" />,
      iconBgColor: '#6C63FF',
      title: 'Monitoramento',
      description:
        'Acompanhe as atualizações mais recentes em processos e julgados no momento de sua disponibilidade.',
      buttonText: 'Ir para Monitoramento',
      route: '/monitoramento',
    },
    {
      icon: <Briefcase size="38" />,
      iconBgColor: '#FFA500',
      title: 'Jurimetria Trabalhista',
      description:
        'Obtenha insights estatísticos para fortalecer sua estratégia jurídica.',
      buttonText: 'Ir para Jurimetria Trabalhista',
      route: '/jurimetria-trabalhista',
    },
  ];

  useEffect(() => {
    document.title = 'Busca Jurisprudencial';
    validTerms();
    entities();
    getSearchHistory();
    getFilters();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    posthog.capture('$pageview');
    getEntitiesToMonitoring();
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem('tour') && localStorage.getItem('profile')) {
  //     const { tour } = JSON.parse(localStorage.getItem('tour'));
  //     const profile = JSON.parse(localStorage.getItem('profile'));
  //     tour.search && profile.onboarding_done
  //       ? setRunTour(false)
  //       : setRunTour(true);
  //     return;
  //   }
  // }, [localStorage.getItem('tour')]);

  const handleClickEntities = () => {
    setEntitiesOpen(true);
  };

  const filterDefault = () => {
    const defaultFields = ['ementa', 'full_text'];

    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const todayFormatted = `${day}/${month}/${year}`;

    const defaultFilters = {
      date_range: {
        0: '01/01/2000',
        1: todayFormatted,
      },
      entities: [
        0, 1, 2, 97, 95, 96, 3, 40, 41, 5, 7, 8, 43, 11, 12, 13, 42, 15, 17, 51,
        18, 19, 23, 24, 25, 26, 28, 29, 27, 6, 4, 31, 33, 10, 30, 32, 34, 36,
        37, 35, 16, 57, 60, 39, 66, 56, 49, 45, 72, 65, 73, 74, 38, 64, 63, 69,
        55, 75, 68, 71, 9, 78, 52, 21, 70, 54, 22, 91, 58, 93, 53, 90, 20, 82,
        85, 83, 86, 80, 79, 87, 48, 94, 50, 89, 77, 44, 46, 59, 84,
      ],
      filter_by_favorability: [0, 1, 2, 3, 4],
      filter_by_orgao_julgador_stf: null,
      filter_by_relator: null,
      precedente_qualificado: [],
      filter_by_secao_carf: null,
      filter_by_vote: null,
    };
    localStorage.setItem('filters', JSON.stringify(defaultFilters));
    localStorage.setItem('fields', JSON.stringify(defaultFields));
  };

  useEffect(() => {
    // EXPLANATION: Se ele bate em /pesquisa -> reset
    if (props.location.pathname === '/pesquisa') {
      filterDefault();
    }
  }, [props.location.pathname]);

  const [isFocused, setIsFocused] = useState(false);
  const searchBarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchBarRef]);

  return (
    <div className={`container-page`}>
      {/* <div className={`container-page ${isFocused ? 'focused-container' : ''}`}>    com background */}
      <BrowserDetection>{browserHandler}</BrowserDetection>
      <BigLaunchMode />
      <Modal open={seeTerms}>
        <Modal.Body>
          <h5>Termos de Uso e Política de Privacidade</h5>
          <br />
          <p>
            Para continuar utilizando a plataforma da Turivius é necessário que
            você esteja ciente e de acordo com os nossos &nbsp;
            <a href="https://turivius.com/termos-de-uso" target="__blank">
              Termos de Uso
            </a>
            &nbsp; e{' '}
            <a
              href="https://turivius.com/politica-de-privacidade"
              target="__blank"
            >
              Política de privacidade
            </a>
            .
          </p>
        </Modal.Body>

        <Modal.Footer>
          {loading ? (
            <Button size="sm" appearance="secondary" disabled={true}>
              <SpinningLoader size="sm" color="gray" />
            </Button>
          ) : (
            <Button size="sm" appearance="secondary" onClick={() => agree()}>
              Li e aceito os Termos de Uso e Política de Privacidade
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item colspan={24}>
          <FlagSystem
            current="jurisprudence"
            openSuccessChecklist={openSuccessChecklist}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          colspan={18}
          // style={{ padding: '0 10px', marginTop: '5px' }} // TODO: novo layout
          style={{ padding: '0 10px', marginTop: '50px' }}
        >
          <div className="title-page">
            <div className="title-page-content">
              <h3>Faça sua pesquisa, otimize seu tempo.</h3>
              <p>
                Escolha se quer buscar da forma tradicional ou pelo número do
                processo.
              </p>
              <div
                ref={searchBarRef}
                className={`${isFocused ? 'focused' : ''}`}
                onClick={() => setIsFocused(true)}
              >
                {' '}
                <SearchBar
                  ref={searchBarRef}
                  className={`${isFocused ? 'focused' : ''}`}
                  onClick={() => setIsFocused(true)}
                  isFocused={isFocused}
                  firstSearch={true}
                  segment={'tributario'}
                  permissions={permissions['tributario']}
                  entitiesPermissions={entitiesPermissions}
                  searchHistory={searchHistory}
                  loadindSearchHistory={requestingSearchHistory}
                  getRelatorsNames={getRelatorsNames}
                  relatorNames={relatorNames}
                  originalFilters={originalFilters}
                  tmpFilters={tmpFilters}
                  inteiroTeorFilter={inteiroTeor}
                  justSaveFilters={justSaveFilters}
                  saveFilters={saveFilters}
                  filters={filters}
                  oldFilters={filters}
                  setTmpFilters={setTmpFilters}
                  entities_counter={entities_counter}
                  clearFilters={clearFilters}
                  filtersProps={props}
                  handleClickEntities={handleClickEntities}
                  AllProps={props}
                  entitiesMonitoring={entitiesMonitoring}
                />
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
        {/* <FlexboxGrid.Item colspan={16} style={{ padding: '0 20px' }}>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              flexWrap: 'wrap',
              // justifyContent: 'space-between',
              justifyContent: 'center',
            }}
          >
            {products_cards.map((card, index) => (
              <CTA
                key={index}
                icon={card.icon}
                iconBgColor={card.iconBgColor}
                title={card.title}
                description={card.description}
                buttonText={card.buttonText}
                route={card.route}
              />
            ))}
          </div>
        </FlexboxGrid.Item> */}

        {/* TODO: Essa Div serve para  gerar um container flex
           e depois facilitar para colocar os elementos lado a lado 
           Caso não exista a div o flexbox frid faz os elementos quebrarem ao lado
           */}
        {/* <FlexboxGrid.Item
          colspan={15}
          style={{
            padding: '10px',
          }}
        >
          
          <div
            style={{
              display: 'flex',
              gap: '20px',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <RecentSearchCard
              title="Pesquisas recentes"
              description="Revise rapidamente consultas realizadas e retome de onde parou."
              buttonText="Ver Histórico"
              icon={<ArchiveBook size="52" color="#FF8A65" />}
            />

            <RecentSearchCard
              title="Assuntos em alta"
              description="Descubra os temas mais discutidos pelos melhores advogados do Brasil."
              buttonText="Ver assuntos"
              icon={<AlignBottom size="52" color="#FF8A65" />}
            />
          </div>
        </FlexboxGrid.Item>
         */}

        <div className="home-cards_container">
          <div className="home-cards " id="hot-topics">
            <div className="home-box-content">
              <RecentSearchBox />
              <RecentArticlesBox />
            </div>
          </div>
        </div>
      </FlexboxGrid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clearFilters: state.searchTributario.clearFilters,
  originalFilters: state.searchTributario.originalFilters,
  tmpFilters: state.searchTributario.tmpFilters,
  inteiroTeor: state.searchTributario.inteiroTeor,
  entities: state.searchTributario.entities,
  permissions: state.profiles.permissions,
  filters: state.searchTributario.filters,
  entities_counter: state.searchTributario.entities_counter,
  query: state.searchTributario.query,
  firstSearch: state.searchTributario.firstSearch,
  filterStr: state.searchTributario.filterStr,
  synonyms: state.searchTributario.synonyms,
  searchMode: state.searchTributario.searchMode,
  defaultOperator: state.searchTributario.defaultOperator,
  expanded: state.searchTributario.expanded,
  validEntities: state.jurimetrics.validEntities,
  externalFilters: state.searchTributario.externalFilters,
  profile: state.profiles.profile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign({}, ProfilesActions, SearchActions),
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(HomePage));
