import React from 'react';
import { CheckTree, Checkbox, CheckboxGroup } from 'rsuite';
import { CustomCheckTree } from './CustomCheckTree';

import { Collapse } from 'react-collapse';
import { Turivius } from '../../../../../ui';
import { posthog } from '../../../../../requesters';
import { ArrowDown2, ArrowUp2, MaskRight } from 'iconsax-react';
import { Button } from '../../../../../ds-components';
import './collapsableSelect.scss';
import { bool } from 'prop-types';
class CollapsableSelect extends React.Component {
  state = {
    requesting: false,
    items: null,
    filters: null,
    selected: [],
    selectedAll: true,
    selectedEntities: [],
    collapse: false,
    isOpened: [],
    permissions: [],
    expandedGroups: {},
    activeButton: null,
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  unifyEntitiesPermissions(permissions) {
    const entities = {};

    let entitiesTributario = permissions['tributario']?.entidades;
    let entitiesTrabalhista = permissions['trabalhista']?.entidades;

    if (entitiesTributario) {
      Object.entries(entitiesTributario).forEach(([key, value]) => {
        entities[key] = value;
      });
    }

    if (entitiesTrabalhista) {
      Object.entries(entitiesTrabalhista).forEach(([key, value]) => {
        entities[key] = value;
      });
    }

    return entities;
  }

  updateUrlWithSelectedEntities(selected) {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);

    const filters = JSON.parse(localStorage.getItem('filters')) || {};

    params.set('filters', JSON.stringify(filters));
    let query_params = decodeURIComponent(params.toString());

    if (window.location.pathname == '/pesquisa/resultados') {
      window.history.replaceState({}, '', `${url.pathname}?${query_params}`);
    }
  }

  updateLocalStorageFilters = (selectedEntities) => {
    const filters = JSON.parse(localStorage.getItem('filters')) || {};
    filters.entities = selectedEntities;
    localStorage.setItem('filters', JSON.stringify(filters));
  };

  componentDidMount() {
    const { options, filterReference, filtersProps, placeholder, resultById } =
      this.props;

    const permissions = this.unifyEntitiesPermissions(
      JSON.parse(localStorage.getItem('permissions')),
    );

    const thePermissions = permissions
      ? Object.keys(permissions)?.map((key) => Number(key))
      : permissions;

    this.setState({ permissions: thePermissions });

    this.mounted = true;

    const filters = JSON.parse(localStorage.getItem('filters')) || {};
    if (this.state.selected) {
      filters.entities = this.state.selected;
      localStorage.setItem('filters', JSON.stringify(filters));
    }
    if (resultById && resultById.length > 0) {
      this.setState({ selected: resultById });
      this.props.filtersProps.setTmpFilters(filterReference, resultById);
      this.updateUrlWithSelectedEntities(resultById);
    } else {
      this.getOptions('');
      if (this.mounted) {
        if (filterReference === 'entities') {
          this.setState({
            filters: filtersProps.filters[filterReference],
            selected: filtersProps.filters[filterReference]?.filter(
              (permission) => thePermissions.includes(permission),
            ),
            placeholder: placeholder,
          });
        } else {
          this.setState({
            filters: filtersProps.filters[filterReference],
            selected: filtersProps.filters[filterReference] || [],
            placeholder: placeholder,
          });
        }

        if (filtersProps.filters[filterReference]?.length === options?.length) {
          this.setState({ selectedAll: true });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { resultById, filterReference } = this.props;
    if (prevProps.resultById !== resultById) {
      this.setState({ selected: resultById });
      this.props.filtersProps.setTmpFilters(filterReference, resultById);
      this.updateUrlWithSelectedEntities(resultById);
    }

    const propsFilters =
      this.props.filtersProps.filters &&
      this.props.filtersProps.filters[this.props.filterReference];
    const stateFilters = this.state && this.state.filters;
    if (!stateFilters && propsFilters && this.mounted) {
      this.setState({
        filters: [...propsFilters],
        selected: [...propsFilters],
      });
    } else if (
      stateFilters &&
      propsFilters &&
      stateFilters.length !== propsFilters.length &&
      this.mounted
    ) {
      this.setState({
        filters: null,
        selected: null,
      });
      setTimeout(() => {
        this.setState({
          filters: [...propsFilters],
          selected: [...propsFilters],
        });
      }, 100);
    } else if (
      Array.isArray(propsFilters) &&
      Array.isArray(stateFilters) &&
      this.mounted
    ) {
      let doUpdate = false;
      propsFilters.forEach((propFilter) => {
        const found = stateFilters.filter((stateFilter) => {
          return (
            (typeof stateFilter === 'object'
              ? parseInt(stateFilter.id)
              : parseInt(stateFilter)) ===
            (typeof propFilter === 'object'
              ? parseInt(propFilter.id)
              : parseInt(propFilter))
          );
        }).length;
        if (found === 0) {
          doUpdate = true;
        }
      });
      if (doUpdate && this.mounted) {
        this.setState({
          filters: null,
          selected: null,
        });
        setTimeout(() => {
          this.setState({
            filters: [...propsFilters],
            selected: [...propsFilters],
          });
        }, 100);
      }
    } else if (
      (propsFilters === undefined || propsFilters === null) &&
      (stateFilters === undefined ||
        stateFilters === null ||
        (Array.isArray(stateFilters) && stateFilters.length !== 0))
    ) {
      this.setState({
        filters: [],
        selected: [],
      });
    }
  }

  getOptions = (str) => {
    if (this.mounted) {
      if (this.props.filterReference === 'entities') {
        this.setState({
          items: this.groupArray(this.props.filter?.options, 'class'),
        });
      } else {
        this.setState({ items: this.props.filter?.options });
      }
    }
  };

  groupArray = (arr, groupBy) => {
    /*SEGMENTATION */
    // const allowedEntities = this.props.permissions
    let groupedArray = [];
    let categories = {};

    if (Array.isArray(arr)) {
      arr.forEach((elem) => {
        categories[elem[groupBy]] = '';
      });
    } else {
      console.error('arr não é um array:', arr);
    }

    Object.keys(categories).forEach((category, i) => {
      groupedArray.push([]);
      arr.forEach((elem) => {
        /*SEGMENTATION */
        // elem.enabled = allowedEntities[elem.id] ?? false
        // elem.enabled = true
        elem[groupBy] === category ? groupedArray[i].push(elem) : null;
      });
    });
    return groupedArray;
  };

  simpleSelectionChange = (e) => {
    // TODO: Altera o estado quando um combobox é selecionado

    const { filterReference, updateSelectedFilters } = this.props;
    let { selected } = this.state;
    let selection = parseInt(e.target.value);
    let findSelection = selected.indexOf(selection);

    if (findSelection === -1) {
      selected = [...selected, selection];
    } else {
      selected.splice(findSelection, 1);
    }
    this.setState({ selected: [...selected] });
    this.props.filtersProps.setTmpFilters(filterReference, [...selected]);
    updateSelectedFilters({ [filterReference]: [...selected] });

    this.updateLocalStorageFilters(selected);
    this.updateUrlWithSelectedEntities(selected);

    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: 'change_filter',
        id: filterReference,
      });
    }

    if (selected.length !== this.props.options.length) {
      this.setState({ selectedAll: false });
    } else {
      this.setState({ selectedAll: true });
    }
  };

  newCheckBoxGroup = (value, checked, event) => {
    this.GroupSelectionChange(event);
  };

  GroupSelectionChange = (e) => {
    //TODO: Essa função é ativada quando um grupo ou hierarquia é selecionada
    // Ele pega todas as entidades inferiores do elemento e faz o toggle

    const { filterReference, updateSelectedFilters } = this.props;
    let { selected } = this.state;

    let isChecked = e.target.checked; // verifica se não está checado para poder colocar as flags
    let entities = JSON.parse(e.target.value);

    let newSelected = [];

    if (isChecked) {
      // essa função pega apenas os entities que nãoe stão no selected (praticamente uma validação dupla aqui)
      let toInsert = entities.filter((element) => !selected.includes(element));

      newSelected = [...selected, ...toInsert];
    } else {
      newSelected = selected.filter((element) => !entities.includes(element));
    }

    this.setState({ selected: [...newSelected] });

    this.props.filtersProps.setTmpFilters(filterReference, [...newSelected]);
    updateSelectedFilters({ [filterReference]: [...newSelected] });

    this.updateLocalStorageFilters(newSelected);
    this.updateUrlWithSelectedEntities(newSelected);

    // let findSelection = selected.indexOf(selection);

    // e.target;
  };

  selectOption = (action) => {
    const { permissions } = this.state;
    const { options, filterReference } = this.props;

    let selectedsAll = [];

    if (action === 'select-all') {
      selectedsAll = Object.keys(options)?.map((option) => options[option].id);

      if (filterReference === 'entities') {
        const filteredSelections = selectedsAll.filter((permission) =>
          permissions.includes(permission),
        );

        this.setState({
          selectedAll: true,
          selected: filteredSelections,
          activeButton: 'select-all',
        });

        this.props.filtersProps.setTmpFilters(
          filterReference,
          filteredSelections,
        );

        this.updateUrlWithSelectedEntities(filteredSelections);
        this.updateLocalStorageFilters(filteredSelections);
      } else {
        this.setState({
          selected: selectedsAll,
          selectedAll: true,
          activeButton: 'select-all',
        });
        this.props.filtersProps.setTmpFilters(filterReference, selectedsAll);

        this.updateUrlWithSelectedEntities(selectedsAll);
      }
    } else if (action === 'remove-all') {
      this.setState({
        selected: [],
        selectedAll: false,
        activeButton: 'remove-all',
      });

      this.props.filtersProps.setTmpFilters(filterReference, []);

      this.updateUrlWithSelectedEntities([]);
    }
  };

  globalSelection = (action) => {
    let { selected, items, permissions } = this.state;
    const { filterReference } = this.props;

    if (action === 'remove') {
      selected = [];
    } else {
      selected = items?.map((item) => item.id);
    }

    if (filterReference === 'entities') {
      selected = [...selected].filter((permission) =>
        permissions.includes(permission),
      );
    }

    this.setState({ selected: [...selected] });
    this.props.filtersProps.setTmpFilters(filterReference, [...selected]);

    this.updateUrlWithSelectedEntities(selected);
    this.updateLocalStorageFilters(selected); // Atualiza o localStorage

    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: 'change_filter',
        id: filterReference,
      });
    }
  };

  selectionGroupEntities = (group, action) => {
    let { selected, selectedEntities, filterReference, permissions } =
      this.state;

    if (action === 'remove') {
      group?.map((item) => {
        let findSelection = selected.indexOf(item.id);
        if (findSelection === -1) {
          selected.push(item.id);
        }
      });
    } else {
      group?.map((item) => {
        let findSelection = selected.indexOf(item.id);

        if (findSelection !== -1) {
          selected.splice(findSelection, 1);
        }
      });

      this.setState({
        selected: [...selected].filter((permission) =>
          permissions.includes(permission),
        ),
      });

      this.props.filtersProps.setTmpFilters('entities', [...selected]);
    }

    this.setState({ selected: [...selected] });

    this.props.filtersProps.setTmpFilters(filterReference, [...selected]);
  };

  changeSelectionGroup = (group, action) => {
    let { selected, permissions } = this.state;

    for (let option of group) {
      let findSelection = selected.indexOf(option.id);
      if (findSelection === -1 && action === 'add') {
        selected.push(option.id);
      } else if (findSelection !== -1 && action === 'remove') {
        selected.splice(findSelection, 1);
        this.setState({ selectedAll: false });
      }
    }

    this.setState({
      selected: [...selected].filter((permission) =>
        permissions.includes(permission),
      ),
    });

    this.props.filtersProps.setTmpFilters('entities', [...selected]);

    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: 'change_filter',
        id: 'entities',
      });
    }
  };

  verifyGroupSelection = (group) => {
    let { selected, permissions } = this.state;
    let allSelected = true;
    let allDisabled = true;

    const theSelected = selected.filter((permission) =>
      permissions.includes(permission),
    );

    for (let option of group.filter((permission) => {
      return permissions.includes(permission.id);
    })) {
      let verifySelection = theSelected.indexOf(option.id);

      if (verifySelection === -1) {
        allSelected = false;
        break;
      }
    }

    return allSelected
      ? {
          type: 'remove',
          label: '',
        }
      : {
          type: 'add',
          label: '',
        };
  };

  checkIsOpened(group) {
    return !!this.state.isOpened.find((_group) => _group.id === group.id);
  }

  onOpened(group) {
    const isFinded = this.state.isOpened.find(
      (_group) => _group.id === group.id,
    );
    if (isFinded)
      return this.setState({
        isOpened: this.state.isOpened.filter(
          (_group) => _group.id !== group.id,
        ),
      });
    return this.setState({
      isOpened: [...this.state.isOpened, group],
    });
  }

  handleGroupSelection = (group, isChecked) => {
    const { selected, permissions } = this.state;

    let updatedSelected = [...selected];

    group.forEach((item) => {
      const index = updatedSelected.indexOf(item.id);

      if (isChecked) {
        if (index === -1 && permissions.includes(item.id)) {
          updatedSelected.push(item.id);
        }
      } else {
        if (index !== -1) {
          updatedSelected.splice(index, 1);
        }
      }
    });

    this.setState({ selected: updatedSelected });

    this.props.filtersProps.setTmpFilters('entities', updatedSelected);
    this.updateUrlWithSelectedEntities(updatedSelected);
    this.updateLocalStorageFilters(updatedSelected);
  };

  isGroupFullySelected = (group) => {
    const { selected, permissions } = this.state;

    if (!Array.isArray(group)) {
      console.error("O parâmetro 'group' não é um array válido:", group);
      return false;
    }

    return group.every(
      (item) => selected.includes(item.id) && permissions.includes(item.id),
    );
  };

  searchByNameOrInitials = (query) => {
    const lowerCaseQuery = query.toLowerCase();

    const filteredItems = this.state.items
      .map((group) =>
        group.filter(
          (item) =>
            item.name.toLowerCase().includes(lowerCaseQuery) ||
            item.initials.toLowerCase().includes(lowerCaseQuery),
        ),
      )
      .filter((group) => group.length > 0);

    this.setState({ lowerCaseQuery });
    this.setState({ filteredItems });
  };

  toggleGroupVisibility = (groupId) => {
    this.setState((prevState) => ({
      expandedGroups: {
        ...prevState.expandedGroups,
        [groupId]: !prevState.expandedGroups[groupId],
      },
    }));
  };

  render() {
    const {
      items,
      requesting,
      selected,
      collapse,
      permissions: thePermissions,
      lowerCaseQuery,
    } = this.state;

    const { label, filterReference, filtersProps, filters } = this.props;
    const { entities_counter } = filtersProps;

    // Use o método find para selecionar o filtro onde field_name é 'entity'
    const entityFilter = filters.find(
      (filter) => filter.field_name === 'entity',
    );

    const allItems = Array.isArray(this.state.items)
      ? this.state.items.flat()
      : [];

    const filterAllItems = allItems.map((item) => item);

    const filteredItems = filterAllItems?.filter((item) =>
      item?.initials?.toLowerCase().includes(lowerCaseQuery),
    );

    const dfsEntities = (arr) => {
      // função que recebe o array dos filtros e percorre ele até o final devolvendo uma lista com todas as entidades
      // basicamente é uma busca em profundidade (recursiva)
      let all_in_this_group = [];
      Object.entries(arr).forEach((key) => {
        if (key[0] === '__items__') {
          all_in_this_group = all_in_this_group.concat(key[1]);
        } else {
          all_in_this_group = all_in_this_group.concat(dfsEntities(key[1]));
        }
      });
      return all_in_this_group;
    };

    const getEntityNumberByArray = (filterEntities) => {
      // pega um array com os objetos entidades e percorre pegand os ids
      const entities = dfsEntities(filterEntities[1]);

      return entities.map((entity) => entity?.id);
    };

    const getCheckedState = (btnID) => {
      // função que verifica se o botão está aberto ou fechado
      let isChecked = this.state.selected.indexOf(btnID) !== -1;

      return isChecked;
    };

    const isGroupChecked = (Groupitem) => {
      // Aqui é como se fosse um componente filho;
      // Isso funciona pq, quando há mudança do estado pai (CollapsableSelect)
      // Os filhos (nesse caso html no render) são alterados
      // Daí as função dentro de isChecked e Indeterminate são chamadas
      let entities = getEntityNumberByArray(Groupitem);

      let isSelected = entities.every((element) =>
        this.state.selected.includes(element),
      );
      return isSelected;
    };

    const isGroupIndeterminate = (Groupitem) => {
      let entities = getEntityNumberByArray(Groupitem);

      let isSelected = entities.filter((element) =>
        this.state.selected.includes(element),
      );

      const some = isSelected.length > 0;

      const notAll = isSelected.length < entities.length;

      return some && notAll;
    };

    const entity_data = filters[0]['options'];

    return (
      // <div className="tv-select_collapsable">
      // <span c/lassName="font-medium">{label}</span>
      <CustomCheckTree data={entity_data} />
      // </div>
    );
    // return (
    //   <div className="tv-select_collapsable">
    //     <div className="tv-select_collapsable-container">
    //       <span className="font-medium">{label}</span>
    //       <div onClick={() => this.setState({ collapse: !collapse })}>
    //         {collapse ? (
    //           <ArrowDown2 color="#9ba2b3" />
    //         ) : (
    //           <ArrowUp2 color="#9ba2b3" />
    //         )}
    //       </div>
    //     </div>

    //     {requesting ? (
    //       <Turivius.SimplePreloader />
    //     ) : items && selected ? (
    //       <div
    //         className={
    //           filterReference === 'entities'
    //             ? collapse
    //               ? 'entities close'
    //               : ''
    //             : `tv-select_collapsable-options ${collapse ? 'close' : ''}`
    //         }
    //       >
    //         <div className="tv-select_search-container">
    //           <img
    //             src="/lupa.png"
    //             alt="Ícone de busca"
    //             className="search-icon"
    //           />
    //           <input
    //             type="text"
    //             placeholder="Buscar o órgão por nome"
    //             style={{ paddingLeft: '30px' }}
    //             className="tv-select_search-input"
    //             onChange={(e) => this.searchByNameOrInitials(e.target.value)}
    //           />
    //         </div>

    //         <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
    //           {filters ? (
    //             <div>
    //               {Object.entries(entityFilter?.options).map((item, _) => (
    //                 <div className="item-collapse-entities" key={item.id}>
    //                   <div
    //                     key={`${item[0]}_${_}`}
    //                     className="item-collapse-entities"
    //                     id={`${item[0]}_${_}`}
    //                   >
    //                     <div className="checkbox-group-header">
    //                       {/* <Checkbox
    //                         type="checkbox"
    //                         name={`Checkbox_${filterReference}_${item[0]}`}
    //                         id={`Checkbox_${filterReference}_${item[0]}`}
    //                         value={JSON.stringify(getEntityNumberByArray(item))}
    //                         onChange={this.newCheckBoxGroup}
    //                         checked={isGroupChecked(item)}
    //                         // checked={true}
    //                         indeterminate={isGroupIndeterminate(item)}
    //                       /> */}

    //                       <input
    //                         type="checkbox"
    //                         name={`Checkbox_${filterReference}_${item[0]}`}
    //                         id={`Checkbox_${filterReference}_${item[0]}`}
    //                         value={JSON.stringify(getEntityNumberByArray(item))}
    //                         onChange={this.GroupSelectionChange}
    //                         checked={isGroupChecked(item)}
    //                       />
    //                       <label
    //                         htmlFor={`Checkbox_${filterReference}_${item[0]}`}
    //                         className="checkbox-label"
    //                       >
    //                         {item[0]}
    //                       </label>
    //                       <span className="counter ">
    //                         {entities_counter && entities_counter[item?.id]
    //                           ? `${entities_counter[item?.id]}`
    //                           : ''}
    //                       </span>
    //                     </div>
    //                     <div className="item-collapse-entities_flags">
    //                       {item?.is_new ? (
    //                         <Turivius.TuriviusTooltip
    //                           trigger={'hover'}
    //                           message={'Base de dados nova!'}
    //                           placement={'right'}
    //                         >
    //                           <em className="item-collapse-entities_flags new">
    //                             Novo
    //                           </em>
    //                         </Turivius.TuriviusTooltip>
    //                       ) : null}
    //                       {/* {!thePermissions.includes(item?.id) && (
    //                         <Turivius.TuriviusTooltip
    //                           trigger={'hover'}
    //                           message={
    //                             'Clique em fazer upgrade do seu plano e entre em contato com nosso atendimento'
    //                           }
    //                           placement={'right'}
    //                         >
    //                           <em
    //                             onClick={() =>
    //                               window.open(
    //                                 `${window.location.href}#hs-chat-open`,
    //                                 '_self',
    //                               )
    //                             }
    //                             className="item-collapse-entities_flags upgrade"
    //                           >
    //                             Upgrade
    //                           </em>
    //                         </Turivius.TuriviusTooltip>)} */}
    //                     </div>
    //                   </div>

    //                   {Object.entries(item[1]) &&
    //                     Object.entries(item[1]).map((item2) => {
    //                       return (
    //                         <div style={{ marginLeft: '10px' }}>
    //                           {item2[0] === '__items__' ? (
    //                             <div className="">
    //                               {item2[1]?.map((item3) => {
    //                                 return (
    //                                   <div>
    //                                     <input
    //                                       type="checkbox"
    //                                       name={`Checkbox_${filterReference}_${item?.id}`}
    //                                       id={`Checkbox_${filterReference}_${item?.id}`}
    //                                       onChange={this.simpleSelectionChange}
    //                                       checked={getCheckedState(item3.id)}
    //                                       value={item3.id}
    //                                     />
    //                                     {item3.initials}{' '}
    //                                     <span className="counter ">
    //                                       {entities_counter &&
    //                                       entities_counter[item3?.id]
    //                                         ? `${entities_counter[item3?.id]}`
    //                                         : ''}
    //                                     </span>
    //                                   </div>
    //                                 );
    //                               })}
    //                             </div>
    //                           ) : (
    //                             <div>
    //                               <div>
    //                                 {/* {' '} */}
    //                                 {/* <Checkbox
    //                                     type="checkbox"
    //                                     name={`Checkbox_${filterReference}_${item?.id}`}
    //                                     id={`Checkbox_${filterReference}_${item?.id}`}
    //                                     onChange={this.newCheckBoxGroup}
    //                                     checked={isGroupChecked(item2)}
    //                                     indeterminate={isGroupIndeterminate(
    //                                       item2,
    //                                     )}
    //                                     value={JSON.stringify(
    //                                       getEntityNumberByArray(item2),
    //                                     )}
    //                                   /> */}
    //                                 <input
    //                                   type="checkbox"
    //                                   name={`Checkbox_${filterReference}_${item?.id}`}
    //                                   id={`Checkbox_${filterReference}_${item?.id}`}
    //                                   onChange={this.GroupSelectionChange}
    //                                   checked={isGroupChecked(item2)}
    //                                   value={JSON.stringify(
    //                                     getEntityNumberByArray(item2),
    //                                   )}
    //                                 />
    //                                 {item2[0]}
    //                               </div>
    //                               <div style={{ marginLeft: '20px' }}>
    //                                 {item2[1].__items__.map((item4) => {
    //                                   return (
    //                                     <div>
    //                                       {' '}
    //                                       <input
    //                                         type="checkbox"
    //                                         name={`Checkbox_${filterReference}_${item?.id}`}
    //                                         id={`Checkbox_${filterReference}_${item?.id}`}
    //                                         onChange={
    //                                           this.simpleSelectionChange
    //                                         }
    //                                         checked={getCheckedState(item4.id)}
    //                                         value={item4.id}
    //                                       />
    //                                       {item4.initials}
    //                                     </div>
    //                                   );
    //                                 })}
    //                               </div>
    //                             </div>
    //                           )}
    //                         </div>
    //                       );
    //                     })}
    //                 </div>
    //               ))}
    //             </div>
    //           ) : (
    //             <>
    //               {filterReference === 'entities'
    //                 ? items?.map((group, i) => (
    //                     <div
    //                       className="checkbox-group-category-container-row-block"
    //                       key={group[i]?.id}
    //                     >
    //                       <div className="header">
    //                         <div className="item-collapse-entities">
    //                           {this.checkIsOpened(group[i]) ? (
    //                             <ArrowUp2
    //                               style={{ marginRight: '10px' }}
    //                               size={12}
    //                               color="#848FAC"
    //                               onClick={(e) => {
    //                                 e.stopPropagation();
    //                                 this.onOpened(group[i]);
    //                               }}
    //                             />
    //                           ) : (
    //                             <ArrowDown2
    //                               style={{ marginRight: '10px' }}
    //                               size={12}
    //                               color="#848FAC"
    //                               onClick={(e) => {
    //                                 e.stopPropagation();
    //                                 this.onOpened(group[i]);
    //                               }}
    //                             />
    //                           )}
    //                           <input
    //                             type="checkbox"
    //                             onChange={(e) =>
    //                               this.handleGroupSelection(
    //                                 items[i],
    //                                 e.target.checked,
    //                               )
    //                             }
    //                             checked={this.isGroupFullySelected(items[i])}
    //                           />
    //                           <span className="title-class">
    //                             {group[i]?.class}
    //                           </span>
    //                         </div>
    //                       </div>
    //                       <Collapse isOpened={this.checkIsOpened(group[i])}>
    //                         <a
    //                           onClick={() =>
    //                             this.changeSelectionGroup(
    //                               group,
    //                               this.verifyGroupSelection(group).type,
    //                             )
    //                           }
    //                           type="button"
    //                           className="tv-select_select-all-link"
    //                         >
    //                           {this.verifyGroupSelection(group).label}
    //                         </a>
    //                         {group
    //                           ?.slice(
    //                             0,
    //                             this.state.expandedGroups[group[i]?.id]
    //                               ? group.length
    //                               : 6,
    //                           )
    //                           .map((elem, j) => (
    //                             <div
    //                               key={`${j}${elem.id}_${elem.name}`}
    //                               className="item-collapse-entities"
    //                               id={elem.id}
    //                             >
    //                               <div className="checkbox-group-header">
    //                                 <div className="chekbox-title">
    //                                   <input
    //                                     type="checkbox"
    //                                     name={`Checkbox_${filterReference}_${elem.id}`}
    //                                     id={`Checkbox_${filterReference}_${elem.id}`}
    //                                     value={`${elem.id}`}
    //                                     disabled={
    //                                       !thePermissions.includes(elem.id)
    //                                     }
    //                                     onChange={simpleSelectionChange}
    //                                     checked={
    //                                       selected
    //                                         ? selected.filter((option) =>
    //                                             typeof option === 'object'
    //                                               ? option.id === elem.id
    //                                               : option === elem.id,
    //                                           ).length > 0
    //                                         : false
    //                                     }
    //                                   />
    //                                   <label
    //                                     htmlFor={`Checkbox_${filterReference}_${elem.id}`}
    //                                     className="checkbox-label"
    //                                   >
    //                                     {filterReference === 'entities'
    //                                       ? elem.initials
    //                                       : elem.name}
    //                                   </label>
    //                                 </div>
    //                                 <div className="checkbox-group-counter">
    //                                   <div className="item-collapse-entities_flags">
    //                                     {elem.is_new ? (
    //                                       <Turivius.TuriviusTooltip
    //                                         trigger={'hover'}
    //                                         message={'Base de dados nova!'}
    //                                         placement={'right'}
    //                                       >
    //                                         <em className="item-collapse-entities_flags new">
    //                                           Novo
    //                                         </em>
    //                                       </Turivius.TuriviusTooltip>
    //                                     ) : null}
    //                                     {!thePermissions.includes(elem.id) && (
    //                                       <Turivius.TuriviusTooltip
    //                                         trigger={'hover'}
    //                                         message={
    //                                           'Clique em fazer upgrade do seu plano e entre em contato com nosso atendimento'
    //                                         }
    //                                         placement={'right'}
    //                                       >
    //                                         <em
    //                                           onClick={() =>
    //                                             window.open(
    //                                               `${window.location.href}#hs-chat-open`,
    //                                               '_self',
    //                                             )
    //                                           }
    //                                           className="item-collapse-entities_flags upgrade"
    //                                         >
    //                                           Upgrade
    //                                         </em>
    //                                       </Turivius.TuriviusTooltip>
    //                                     )}
    //                                   </div>
    //                                   <span className="counter">
    //                                     {entities_counter &&
    //                                     entities_counter[elem.id]
    //                                       ? `${entities_counter[elem.id]}`
    //                                       : '0'}
    //                                   </span>
    //                                 </div>
    //                               </div>
    //                             </div>
    //                           ))}
    //                         {group.length > 6 && (
    //                           <Button
    //                             className="show-more-btn"
    //                             appearance="minimal"
    //                             type="button"
    //                             onClick={(event) => {
    //                               event.preventDefault();
    //                               this.toggleGroupVisibility(group[i]?.id);
    //                             }}
    //                           >
    //                             {this.state.expandedGroups[group[i]?.id]
    //                               ? '- Ver menos'
    //                               : '+ Ver mais'}
    //                           </Button>
    //                         )}
    //                       </Collapse>
    //                     </div>
    //                   ))
    //                 : items?.map((elem, j) => (
    //                     <div key={elem.id}>
    //                       <input
    //                         type="checkbox"
    //                         name={`Checkbox_${filterReference}_${elem.id}`}
    //                         id={`Checkbox_${filterReference}_${elem.id}`}
    //                         value={`${elem.id}`}
    //                         onChange={this.selectionChange}
    //                         checked={
    //                           selected
    //                             ? selected.filter((option) =>
    //                                 typeof option === 'object'
    //                                   ? option.id === elem.id
    //                                   : option === elem.id,
    //                               ).length > 0
    //                             : false
    //                         }
    //                       />
    //                       <label
    //                         htmlFor={`Checkbox_${filterReference}_${elem.id}`}
    //                       >
    //                         {elem.initials}
    //                       </label>
    //                     </div>
    //                   ))}
    //             </>
    //           )}
    //         </div>
    //       </div>
    //     ) : null}
    //   </div>
    // );
  }
}

export default CollapsableSelect;
