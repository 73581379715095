import { useState, useEffect, useRef } from 'react';
import { Col, Progress } from 'rsuite';
import { requester } from '../../../requesters';
import Confetti from 'react-dom-confetti';
import vars from '../../../vars.module.scss';

import { ArrowLeft } from 'iconsax-react';
import Card from '../components/Card';
import Step1 from '../components/Step1';
import Step2 from '../components/Step2';
import Step3 from '../components/Step3';

const Setup = ({}) => {
  const formRef = useRef();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(25);
  const [uploading, setUploading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [formError, setFormError] = useState({});
  const [profile, setProfile] = useState(false);
  const [work, setWork] = useState(null);
  const [area, setArea] = useState(null);
  const [search, setSearch] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [explode, setExplode] = useState(false);

  const [workOptions, _] = useState([
    { value: 'DJ', name: 'work', label: 'Departamento Jurídico' },
    { value: 'EA', name: 'work', label: 'Escritório de advocacia' },
  ]);
  const [areaOptions, __] = useState([
    { value: 'Ambiental', name: 'area', label: 'Ambiental' },
    { value: 'Cível', name: 'area', label: 'Cível' },
    { value: 'Consumidor', name: 'area', label: 'Consumidor' },
    { value: 'Empresarial', name: 'area', label: 'Empresarial' },
    { value: 'Penal', name: 'area', label: 'Penal' },
    { value: 'Trabalhista', name: 'area', label: 'Trabalhista' },
    { value: 'Tributária', name: 'area', label: 'Tributária' },
    { value: 'Outros', name: 'area', label: 'Outros' },
  ]);

  const config = {
    angle: 120,
    spread: 45,
    startVelocity: 45,
    elementCount: 100,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 0,
    width: '4px',
    height: '4px',
    colors: [
      vars.turivius,
      vars.secondary,
      vars.turivius_light,
      vars.secondary_light,
      vars.turivius_dark,
      vars.secondary_dark,
    ],
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    const userId = localStorage.getItem('user');

    requester.profiles
      .getOnboardingUserById(userId)
      .then((response) => {
        console.log(response);
        setProfile(true);
        setName(response.name);
        setEmail(response.email);
        setPhone(response.phone ? response.phone : undefined);
        setPhoto(response.photo);
        setWork(response.occupation_area);
        setArea(response.law_area);
        setSearch(response.performs_jurisprudential_search);
        setUploading(false);
      })
      .catch((err) => console.error(err));
  };

  const previewFile = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      canvas.width = 200; // target width
      canvas.height = 200; // target height

      let image = new Image();
      image.onload = () => {
        const aspect = image.height / image.width;
        ctx.drawImage(
          image,
          0,
          0,
          image.width,
          image.height,
          (canvas.width - canvas.width / aspect) / 2,
          0,
          canvas.width / aspect,
          canvas.height,
        );
        let photo = canvas.toDataURL().split(',')[1];
        patchImageMethod(photo);
      };

      image.src = e.target.result;
      setFileInfo(reader.result);
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const patchImageMethod = (photo) => {
    setUploading(true);
    requester.profiles
      .updateProfileImage(localStorage.getItem('user'), photo)
      .then((response) => {
        setName(name);
        setEmail(email);
        setPhone(phone ? phone : undefined);
        setPhoto(null);

        getProfile();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setStep(step + 1);
      if (step + 1 == 3) {
        setProgress(100);
        updateUser();
        explodeFunction();
      } else if (step < 3) {
        setProgress(progress + 25);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const updateUser = () => {
    const data = {
      name: name,
      phone: phone,
      email: email,
      law_area: area,
      occupation_area: work,
      performs_jurisprudential_search: search,
    };
    // console.log(data)
    requester.profiles
      .updateOnboardingUser(localStorage.getItem('user'), data)
      .then((response) => {
        // console.log("response", response)
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const explodeFunction = () => {
    setExplode(true);
    setTimeout(() => {
      setExplode(false);
    }, 1500);
  };
  const back = () => {
    setStep(step - 1);
    if (step === 3) {
      setProgress(progress - 50);
    }
    if (step < 3) {
      setProgress(progress - 25);
    }
  };
  // useEffect(() => {
  //   console.log(area);
  // }, [area]);
  return (
    <>
      <div className="turivius-setup-modal">
        <Col lg={9} md={9} sm={24} xs={24} className="form-trial">
          <div className="form-container">
            {!profile ? (
              <p className="loading">Carregando...</p>
            ) : (
              <>
                <img
                  src="/new_logo.png"
                  alt="Logo Turivius"
                  className="modal-logo"
                />
                <div className="line">
                  {step > 1 ? (
                    <button type="button" onClick={() => back()}>
                      <ArrowLeft size="20" color="#98A2B3" />{' '}
                    </button>
                  ) : null}
                  <Progress.Line
                    percent={progress}
                    showInfo={false}
                    strokeColor="#0069F5"
                  />
                  <Confetti active={explode} config={config} />
                </div>
                {step === 3 ? (
                  <span className="sub-title">Parabéns, perfil completo!</span>
                ) : null}
                <h2 className="title">
                  {step < 3 ? (
                    'Vamos configurar seu perfil'
                  ) : (
                    <b>
                      Como você pretende iniciar <br /> sua jornada na Turivius?
                    </b>
                  )}
                </h2>
                <span className="description">
                  {step === 1 ? (
                    <span>
                      {' '}
                      São 3 passos e você estará pronto para usar a Turivius.
                    </span>
                  ) : step === 3 ? (
                    <span>A escolha não limitará suas opções na Turivius.</span>
                  ) : null}
                </span>

                {step === 1 ? (
                  <Step1
                    formRef={formRef}
                    setFormError={setFormError}
                    email={email}
                    phone={phone}
                    setPhone={setPhone}
                    formError={formError}
                    loading={loading}
                    handleSubmit={handleSubmit}
                    previewFile={previewFile}
                    setUploading={setUploading}
                  />
                ) : step === 2 ? (
                  <Step2
                    formRef={formRef}
                    setFormError={setFormError}
                    formError={formError}
                    loading={loading}
                    handleSubmit={handleSubmit}
                    workOptions={workOptions}
                    work={work}
                    setWork={setWork}
                    areaOptions={areaOptions}
                    area={area}
                    setArea={setArea}
                    setSearch={setSearch}
                    search={search}
                  />
                ) : step === 3 ? (
                  <Step3 />
                ) : null}
              </>
            )}
            {step === 3 ? (
              <span className="info-text">
                Não se preocupe, você vai poder navegar por qualquer opção
                dentro da plataforma.
              </span>
            ) : null}
          </div>
        </Col>
        {step != 3 ? (
          <Col lg={15} md={15} sm={24} xs={24} className="call-to-action">
            <div className="call-text">
              <span>Perfis completos nos ajudam</span> a entregar uma <br />
              melhor experiência dentro da plataforma
            </div>
            <Card
              name={name}
              image={photo}
              imageLoading={uploading}
              work={work}
              workOptions={workOptions}
            />
          </Col>
        ) : (
          <Col lg={15} md={15} sm={24} xs={24} className="call-to-action-last">
            <div className="call-text">
              Com a Turivius, você encontrará o melhor precedente em menos tempo
              e passará a gerir o <br />
              conhecimento da equipe com foco em melhores resultados.
            </div>
            <img className="trial-image" src="/bg.png" />
          </Col>
        )}
      </div>
    </>
  );
};
export default Setup;
