import { createActions, createReducer } from 'reduxsauce';

const SEARCH_MODE_OPTIONS = {
  NORMAL: 'normal',
  N_PROCESSO: 'nproc',
};

export const { Types, Creators } = createActions({
  trabalhistaChangeInteiroTeor: ['inteiroTeor'],
  trabalhistaChangeDefaultOperator: ['defaultOperator'],
  trabalhistaChangeSynonyms: ['synonyms'],
  trabalhistaSetQuery: ['query'],
  trabalhistaSetFilterStr: ['filterStr'],
  trabalhistaToggleExpanded: [],
  trabalhistaSetTmpFilters: ['key', 'value'],
  trabalhistaSaveFilters: ['history', 'match', 'query'],
  trabalhistaJustSaveFilters: ['filterStr'],
  trabalhistaLoadFilters: [],
  trabalhistaSetDefaultFilters: ['defaultFilters'],
  trabalhistaClearFilters: ['permissions'],
  trabalhistaVerifyTmpFiltersEntitiesPermission: ['permissions'],
  trabalhistaRestoreOptionalFilters: [],
  trabalhistaSetOriginalFilters: ['filters'],
  trabalhistaSetEntities: ['entities'],
  trabalhistaSetExternalFilter: ['filter', 'value'],
  trabalhistaSetEntitiesCounter: ['entities_counter'],
});

const INITIAL_STATE = {
  query: '',
  expanded: false,
  tmpFilters: {},
  filters: {},
  defaultFilters: {},
  originalFilters: {},
  entities: {},
  filterStr: '',
  inteiroTeor: ['ementa', 'full_text'],
  defaultOperator: 'e',
  synonyms: true,
  searchMode: SEARCH_MODE_OPTIONS.NORMAL,
  externalFilters: {
    filter_by_relator: [],
  },
  entities_counter: {},
};

const trabalhistaChangeInteiroTeor = (state = INITIAL_STATE, action) => {
  let inteiroTeor;
  if (typeof action.inteiroTeor === 'string') {
    try {
      inteiroTeor = JSON.parse(action.inteiroTeor);
    } catch (e) {
      inteiroTeor = action.inteiroTeor.split(',');
    }
  } else {
    inteiroTeor = action.inteiroTeor;
  }
  return {
    ...state,
    inteiroTeor,
  };
};

const trabalhistaChangeDefaultOperator = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    defaultOperator: action.defaultOperator,
  };
};

const trabalhistaChangeSynonyms = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    synonyms: action.synonyms,
  };
};

const trabalhistaSetEntities = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    entities: { ...action.entities },
  };
};

const trabalhistaSetQuery = (state = INITIAL_STATE, action) => {
  const searchbar = document.querySelectorAll('#search-bar')[0];
  if (searchbar && searchbar.classList) {
    searchbar.classList.remove('invalid');
  }
  return {
    ...state,
    query: action.query,
  };
};

const trabalhistaSetFilterStr = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    filterStr: action.filterStr,
  };
};

const trabalhistaToggleExpanded = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    expanded: !state.expanded,
  };
};

const trabalhistaSetTmpFilters = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    tmpFilters: {
      ...state.tmpFilters,
      [action.key]: action.value,
    },
  };
};

const trabalhistaSetOriginalFilters = (state = INITIAL_STATE, action) => {
  let originalFilters = {};
  action.filters.forEach((filter) => {
    if (filter.field_name === 'entity') {
      originalFilters.entities = filter;
    } else {
      originalFilters[filter.field_name] = filter;
    }
  });
  return {
    ...state,
    originalFilters,
  };
};

const trabalhistaSetDefaultFilters = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    defaultFilters: action.defaultFilters,
  };
};

const trabalhistaLoadFilters = (state = INITIAL_STATE, action) => {
  const filters = JSON.parse(localStorage.getItem('filters_trabalhista'));
  let filtersToLoad = {};
  if (filters) {
    filtersToLoad = { ...filters };
  }
  Object.keys(state.defaultFilters).forEach((key) => {
    if (!filtersToLoad[key]) {
      filtersToLoad[key] = state.defaultFilters[key];
    }
  });
  return {
    ...state,
    filters: { ...filtersToLoad },
    tmpFilters: { ...filtersToLoad },
  };
};

const trabalhistaRestoreOptionalFilters = (state = INITIAL_STATE, action) => {
  let newFilters = {};
  Object.keys(state.defaultFilters).forEach((key) => {
    if (!newFilters[key] && !(key === 'entity' || key === 'entities')) {
      newFilters[key] = state.defaultFilters[key];
    }
  });
  newFilters = {
    ...state.filters,
    ...newFilters,
  };
  localStorage.setItem('filters_trabalhista', JSON.stringify(newFilters));
  return {
    ...state,
    filters: { ...newFilters },
    tmpFilters: { ...newFilters },
    filterStr: buildFilterUrl(newFilters),
    entities_counter: {},
    inteiroTeor: ['ementa', 'full_text'],
    defaultOperator: 'e',
    synonyms: true,
  };
};

const trabalhistaClearFilters = (state = INITIAL_STATE, action) => {
  let newFilters = {};
  Object.keys(state.defaultFilters).forEach((key) => {
    if (!newFilters[key]) {
      newFilters[key] = state.defaultFilters[key];
    }
  });
  return {
    ...state,
    filters: { ...newFilters },
    tmpFilters: { ...newFilters },
    inteiroTeor: ['ementa', 'full_text'],
    defaultOperator: 'e',
    synonyms: true,
  };
};

const buildFilterUrl = (reduxFilters) => {
  const filters = {};
  Object.keys(reduxFilters).forEach((filterKey) => {
    if (filterKey === 'entities') {
      filters.entity = reduxFilters[filterKey]?.map((rFilter) =>
        typeof rFilter === 'object' ? rFilter.id : rFilter,
      );
    } else {
      if (reduxFilters[filterKey]) {
        filters[filterKey] =
          typeof reduxFilters[filterKey] === 'object'
            ? Array.isArray(reduxFilters[filterKey])
              ? reduxFilters[filterKey]?.map((rFilter) =>
                  typeof rFilter === 'object' ? rFilter.id : rFilter,
                )
              : reduxFilters[filterKey]
            : parseInt(reduxFilters[filterKey]);
      }
    }
  });
  return JSON.stringify(filters);
};

const trabalhistaJustSaveFilters = (state = INITIAL_STATE, action) => {
  let filters = {
    ...JSON.parse(action.filterStr),
    entities: JSON.parse(action.filterStr).entity,
  };
  delete filters.entity;

  localStorage.setItem('filters_trabalhista', JSON.stringify(filters));
  return {
    ...state,
    filters: {
      ...filters,
    },
    tmpFilters: {
      ...filters,
    },
    filterStr: buildFilterUrl(filters),
  };
};

const trabalhistaSaveFilters = (state = INITIAL_STATE, action) => {
  localStorage.setItem('filters_trabalhista', JSON.stringify(state.tmpFilters));
  const filtersString = buildFilterUrl(state.tmpFilters);
  if (action.history) {
    setTimeout(() => {
      const inteiroTeor = state.inteiroTeor
        ? typeof state.inteiroTeor === 'string'
          ? state.inteiroTeor
          : JSON.stringify(state.inteiroTeor)
        : null;
      const defaultOperator = state.defaultOperator;
      const synonyms = state.synonyms;
      const service = 'trabalhista';
      if (action.query) {
        const urlString = `${service ? '/' + service : ''}/resultados?query=${
          action.query
        }${inteiroTeor ? `&fields=${inteiroTeor}` : ''}${
          defaultOperator ? `&default_operator=${defaultOperator}` : ''
        }${synonyms !== null ? `&synonyms=${synonyms}` : ''}${
          filtersString !== '' && filtersString
            ? `&filters=${filtersString}`
            : ''
        }${searchMode ? `&search_mode=${searchMode}` : `&search_mode=${SEARCH_MODE_OPTIONS.NORMAL}`}`;
        action.history.push(urlString);
        localStorage.setItem('lastSearch_trabalhista', urlString);
      } else {
        const urlString = `${service ? '/' + service : ''}/resultados?query=${
          state.query
        }${inteiroTeor ? `&fields=${inteiroTeor}` : ''}${
          defaultOperator ? `&default_operator=${defaultOperator}` : ''
        }${synonyms !== null ? `&synonyms=${synonyms}` : ''}${
          filtersString !== '' && filtersString
            ? `&filters=${filtersString}`
            : ''
        }${searchMode ? `&search_mode=${searchMode}` : `&search_mode=${SEARCH_MODE_OPTIONS.NORMAL}`}`;
        action.history.push(urlString);
        localStorage.setItem('lastSearch_trabalhista', urlString);
      }
    }, 100);
  }
  return {
    ...state,
    filters: {
      ...state.tmpFilters,
    },
    filterStr: filtersString,
  };
};

const trabalhistaSetExternalFilter = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    externalFilters: {
      ...state.externalFilters,
      [action.filter]: action.value,
    },
  };
};

const trabalhistaSetEntitiesCounter = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    entities_counter: {
      ...action.entities_counter,
    },
  };
};

const trabalhistaVerifyTmpFiltersEntitiesPermission = (
  state = INITIAL_STATE,
  action,
) => {
  let { tmpFilters } = state;

  return {
    ...state,
    tmpFilters,
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.TRABALHISTA_CHANGE_INTEIRO_TEOR]: trabalhistaChangeInteiroTeor,
  [Types.TRABALHISTA_CHANGE_DEFAULT_OPERATOR]: trabalhistaChangeDefaultOperator,
  [Types.TRABALHISTA_CHANGE_SYNONYMS]: trabalhistaChangeSynonyms,
  [Types.TRABALHISTA_SET_QUERY]: trabalhistaSetQuery,
  [Types.TRABALHISTA_SET_FILTER_STR]: trabalhistaSetFilterStr,
  [Types.TRABALHISTA_TOGGLE_EXPANDED]: trabalhistaToggleExpanded,
  [Types.TRABALHISTA_SET_TMP_FILTERS]: trabalhistaSetTmpFilters,
  [Types.TRABALHISTA_SAVE_FILTERS]: trabalhistaSaveFilters,
  [Types.TRABALHISTA_JUST_SAVE_FILTERS]: trabalhistaJustSaveFilters,
  [Types.TRABALHISTA_LOAD_FILTERS]: trabalhistaLoadFilters,
  [Types.TRABALHISTA_SET_DEFAULT_FILTERS]: trabalhistaSetDefaultFilters,
  [Types.TRABALHISTA_SET_ORIGINAL_FILTERS]: trabalhistaSetOriginalFilters,
  [Types.TRABALHISTA_CLEAR_FILTERS]: trabalhistaClearFilters,
  [Types.TRABALHISTA_RESTORE_OPTIONAL_FILTERS]:
    trabalhistaRestoreOptionalFilters,
  [Types.TRABALHISTA_SET_ENTITIES]: trabalhistaSetEntities,
  [Types.TRABALHISTA_SET_EXTERNAL_FILTER]: trabalhistaSetExternalFilter,
  [Types.TRABALHISTA_SET_ENTITIES_COUNTER]: trabalhistaSetEntitiesCounter,
  [Types.TRABALHISTA_VERIFY_TMP_FILTERS_ENTITIES_PERMISSION]:
    trabalhistaVerifyTmpFiltersEntitiesPermission,
});
