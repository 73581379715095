export const transformTesteToDataQuery = (valueTransform) => {
  const params = new URLSearchParams(window.location.search);
  const queryParam = params.get('query');

  const formatDate = (date) => {
    if (date && typeof date === 'string') {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    }
    return date;
  };

  const startDateFormatted = formatDate(valueTransform.start_date);
  const endDateFormatted = formatDate(valueTransform.end_date);

  const filters = {
    sort: 1,
    date_range: {
      0: startDateFormatted,
      1: endDateFormatted,
    },
    filter_by_favorability: valueTransform.filter_by_favorability,
    filter_by_relator: valueTransform.filter_by_relator,
    filter_by_secao_carf: valueTransform.filter_by_secao_carf,
    filter_by_vote: valueTransform.filter_by_vote,
    entity: valueTransform.entities,
  };

  return {
    query: queryParam,
    page_n: 0,
    fields: valueTransform?.where_search,
    filters: JSON.stringify(filters),
    default_operator: 'e',
    synonyms: true,
  };
};

export const clearFilter = () => {
  const params = new URLSearchParams(window.location.search);
  const queryParam = params.get('query');

  const filters = {
    sort: 1,
    date_range: {
      0: '03/10/18',
      1: '05/02/3000',
    },
    filter_by_favorability: [],
    filter_by_relator: [],
    carf_section: [],
    precedente_qualificado: [],
    filter_by_secao_carf: [],
    filter_by_vote: [],
    entity: [
      0, 1, 2, 97, 95, 96, 3, 40, 41, 5, 7, 8, 43, 11, 12, 13, 42, 15, 17, 51,
      18, 19, 23, 24, 25, 26, 28, 29, 27, 6, 4, 31, 33, 10, 30, 32, 34, 36, 37,
      35, 16, 57, 60, 39, 66, 56, 49, 45, 72, 65, 73, 74, 38, 64, 63, 69, 55,
      75, 68, 71, 9, 78, 52, 21, 70, 54, 22, 91, 58, 93, 53, 90, 20, 82, 85, 83,
      86, 80, 79, 87, 48, 94, 50, 89, 77, 44, 46, 59, 84,
    ],
  };

  return {
    query: queryParam,
    page_n: 0,
    fields: ['ementa', 'full_text'],
    filters: JSON.stringify(filters),
    default_operator: 'e',
    synonyms: true,
  };
};
