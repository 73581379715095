import React from 'react';
import HomePage from './Search';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { requester } from '../../../../requesters';
import { Creators as JurimetricsActions } from '../../../../store/ducks/jurimetrics';
import { Creators as CollectionsActions } from '../../../../store/ducks/collections';
import { Creators as ResultsActions } from '../../../../store/ducks/searchTributario';
import { Creators as ProfilesActions } from '../../../../store/ducks/profiles';

class AutoCompleteSearch extends React.Component {
  state = {
    votes: {},
    countResults: null,
    generalCount: null,
    entitiesPermissions: {},
  };

  getVotes = (ids) => {
    requester.cards
      .getManyVotes(ids)
      .then((response) => {
        let votes = { ...this.state.votes };
        ids.forEach((id) => {
          votes[id] = null;
        });
        response.forEach((vote) => {
          votes[vote.card_id] = vote;
        });
        this.setState({ votes });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getVote = (cardId) => {
    requester.cards
      .getVotes(cardId)
      .then((response) => {
        const votes = { ...this.state.votes, [cardId]: response };
        this.setState({ votes });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  search = (dataQuery) => {
    if (!dataQuery) {
      dataQuery = { query: '' };
    }
    return requester.cards
      .search(dataQuery, 'Search')
      .then((response) => {
        const transformedRelatores = Object.entries(response.relatores).map(
          ([key, value]) => ({
            key,
            doc_count: value.doc_count,
            related_names: value.related_names.join(','),
          }),
        );

        this.props.setExternalFilter('filter_by_relator', transformedRelatores);
        return response;
      })
      .catch((response) => {
        console.log(response);
      });
  };

  getCountResults = (data_query) => {
    requester.cards
      .searchCount(data_query)
      .then((response) => {
        this.props.setEntitiesCounter(
          response.count_by_entity.reduce((obj, item) => {
            return {
              ...obj,
              [item.entity]: item.count,
            };
          }, {}),
        );

        let filteredCounts = {};

        try {
          filteredCounts = response?.count_by_precedente?.reduce(
            (acc, item) => {
              acc[item.name] = item.count;
              return acc;
            },
            {},
          );
        } catch {}

        this.setState({
          countResults: response?.n_results,
          generalCount: {
            secao_carf: response?.count_by_secao_carf,
            rr: filteredCounts?.rr,
            rg: filteredCounts?.rg,
            rrc: filteredCounts?.rrc,
            favorabilidade: response?.count_by_favorability_home,
            voto: response?.voto,
          },
        });

        // EXPLANATION: Aqui fica a contagem de outros filtros
        this.props.setGeneralCounter({
          secao_carf: response?.count_by_secao_carf,
          rr: filteredCounts?.count_by_rr,
          rg: filteredCounts?.count_by_rg,
          rrc: filteredCounts?.rrc,
          favorabilidade: response?.count_by_favorability_home,
          voto: response?.voto,
        });
      })
      .catch((err) => console.error(err));
  };

  getEntities = () => {
    let res = null;
    requester.cards.getAllEntities().then((response) => (res = response));
    return res;
  };

  entities = () => {
    const uhum = {};
    let permissions_trib = JSON.parse(localStorage.getItem('permissions'))[
      'tributario'
    ].entidades;
    let permissions_trab = JSON.parse(localStorage.getItem('permissions'))[
      'trabalhista'
    ].entidades;

    if (permissions_trib) {
      Object.entries(permissions_trib).forEach(([key, value]) => {
        uhum[key] = value;
      });
    }

    if (permissions_trab) {
      Object.entries(permissions_trab).forEach(([key, value]) => {
        uhum[key] = value;
      });
    }

    this.setState({ entitiesPermissions: uhum });
    return uhum;
  };

  getBoardsPage = async (page) => {
    return requester.collections.getUserCollections(
      localStorage.getItem('user'),
      page,
      true,
    );
  };

  getPlots(data_query, filters) {
    return requester.jurimetrics.getSearchPlots({
      ...data_query,
      filters: JSON.stringify(filters),
    });
  }

  loadMoreResults = (data_query) => {
    return requester.cards.loadMore(data_query);
  };

  componentDidMount() {
    document.title = 'Resultados da busca';
    this.entities();
  }

  render() {
    const { votes, countResults, generalCount, entitiesPermissions } =
      this.state;
    const {
      entities,
      userCollections,
      setQuery,
      setEntities,
      setRequestingUserCollections,
      setUserCollections,
      justSaveFilters,
      changeInteiroTeor,
      changeSearchMode,
      changeDefaultOperator,
      changeSynonyms,
      setFilterStr,
      restoreOptionalFilters,
      setEntitiesCounter,
      setGeneralCounter,
      setExternalFilter,
      setOriginalFilters,
      setDefaultFilters,
      loadFilters,
      saveFilters,
      expanded,
      toggleExpanded,
      validEntities,
      filterStr,
      filters,
      tmpFilters,
    } = this.props;

    const permissions = JSON.parse(localStorage.getItem('permissions'))[
      'tributario'
    ];

    return (
      <HomePage
        context={'Results'}
        segment={'tributario'}
        getVotes={this.getVotes}
        getVote={this.getVote}
        getCountResults={this.getCountResults}
        getEntities={this.getEntities}
        getBoardsPage={this.getBoardsPage}
        getPlots={this.getPlots}
        loadMoreResults={this.loadMoreResults}
        votes={votes}
        countResults={countResults}
        generalCount={generalCount}
        search={this.search}
        entitiesPermissions={entitiesPermissions}
        permissions={permissions}
        entities={entities}
        userCollections={userCollections}
        justSaveFilters={justSaveFilters}
        restoreOptionalFilters={restoreOptionalFilters}
        changeInteiroTeor={changeInteiroTeor}
        changeSearchMode={changeSearchMode}
        changeDefaultOperator={changeDefaultOperator}
        changeSynonyms={changeSynonyms}
        setEntities={setEntities}
        setFilterStr={setFilterStr}
        setQuery={setQuery}
        setRequestingUserCollections={setRequestingUserCollections}
        setUserCollections={setUserCollections}
        setEntitiesCounter={setEntitiesCounter}
        setGeneralCounter={setGeneralCounter}
        setExternalFilter={setExternalFilter}
        setOriginalFilters={setOriginalFilters}
        setDefaultFilters={setDefaultFilters}
        loadFilters={loadFilters}
        saveFilters={saveFilters}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        validEntities={validEntities}
        filters={filters}
        filterStr={filterStr}
        tmpFilters={tmpFilters}
        filtersRedux={this.props}
      />
    );
  }
}

// EXPLANATION: Mapeada qual parte do estado do redux irá virar props
const mapStateToProps = (state) => ({
  entities: state.searchTributario.entities,
  query: state.searchTributario.query,
  firstSearch: state.searchTributario.firstSearch,
  filterStr: state.searchTributario.filterStr,
  userCollections: state.collections.userCollections,
  permissions: state.profiles.permissions,
  inteiroTeor: state.searchTributario.inteiroTeor,
  synonyms: state.searchTributario.synonyms,
  searchMode: state.searchTributario.searchMode,
  defaultOperator: state.searchTributario.defaultOperator,
  originalFilters: state.searchTributario.originalFilters,
  filters: state.searchTributario.filters,
  entities_counter: state.searchTributario.entities_counter,
  expanded: state.searchTributario.expanded,
  validEntities: state.jurimetrics.validEntities,
  tmpFilters: state.searchTributario.tmpFilters,
  externalFilters: state.searchTributario.externalFilters,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign(
      {},
      JurimetricsActions,
      CollectionsActions,
      ResultsActions,
      ProfilesActions,
    ),
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AutoCompleteSearch));
